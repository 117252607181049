/**
 * @generated SignedSource<<33202d8368635ac3e56d5d798eb9f4bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelHome_model$data = {
  readonly eyeColor: string | null | undefined;
  readonly hairColor: string | null | undefined;
  readonly height: number | null | undefined;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly contentType: string;
    readonly fullWidth: boolean | null | undefined;
    readonly height: number | null | undefined;
    readonly id: string;
    readonly width: number | null | undefined;
  }> | null | undefined;
  readonly info: string | null | undefined;
  readonly instagramName: string | null | undefined;
  readonly location: string | null | undefined;
  readonly maxSize: number | null | undefined;
  readonly minSize: number | null | undefined;
  readonly polaroids: ReadonlyArray<{
    readonly id: string;
    readonly url: string;
  }> | null | undefined;
  readonly publicName: string | null | undefined;
  readonly shoeSize: number | null | undefined;
  readonly size: number | null | undefined;
  readonly statuses: ReadonlyArray<string> | null | undefined;
  readonly videos: ReadonlyArray<{
    readonly height: number | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly playlistUrl: string | null | undefined;
    readonly width: number | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "ModelHome_model";
};
export type ModelHome_model$key = {
  readonly " $data"?: ModelHome_model$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelHome_model">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelHome_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shoeSize",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hairColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagramName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModelImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        },
        (v2/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullWidth",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "polaroids",
      "args": [
        {
          "kind": "Literal",
          "name": "category",
          "value": "polaroids"
        },
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        }
      ],
      "concreteType": "ModelImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "images(category:\"polaroids\",limit:1)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statuses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "ready",
          "value": true
        }
      ],
      "concreteType": "ModelVideo",
      "kind": "LinkedField",
      "name": "videos",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playlistUrl",
          "storageKey": null
        },
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": "videos(ready:true)"
    }
  ],
  "type": "Model",
  "abstractKey": null
};
})();

(node as any).hash = "4dd6c100c256a24f6f1dbcc136c031b7";

export default node;
