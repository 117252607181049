/**
 * @generated SignedSource<<65a23aef977789126bf7fe4f2df84ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelsHomeQuery$variables = {
  anyOfStatus?: ReadonlyArray<string> | null | undefined;
  pageName: string;
};
export type ModelsHomeQuery$data = {
  readonly models: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ModelGrid_models">;
  }>;
  readonly page: {
    readonly content: string | null | undefined;
    readonly title: string | null | undefined;
  } | null | undefined;
};
export type ModelsHomeQuery = {
  response: ModelsHomeQuery$data;
  variables: ModelsHomeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "anyOfStatus"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "anyOfStatus",
    "variableName": "anyOfStatus"
  },
  {
    "kind": "Literal",
    "name": "visible",
    "value": true
  }
],
v2 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "namePath.0",
        "variableName": "pageName"
      }
    ],
    "kind": "ListValue",
    "name": "namePath"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelsHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModelGrid_models"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModelsHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eyeColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shoeSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hairColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "limit",
                "value": 2
              }
            ],
            "concreteType": "ModelImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": "images(limit:2)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "male",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastContentUpdateDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8917d2a395c03e1d5322039e275c8fb",
    "id": null,
    "metadata": {},
    "name": "ModelsHomeQuery",
    "operationKind": "query",
    "text": "query ModelsHomeQuery(\n  $anyOfStatus: [String!]\n  $pageName: String!\n) {\n  models(visible: true, anyOfStatus: $anyOfStatus) {\n    ...ModelGrid_models\n    id\n  }\n  page(namePath: [$pageName]) {\n    title\n    content\n    id\n  }\n}\n\nfragment ModelBox_model on Model {\n  minSize\n  maxSize\n  size\n  eyeColor\n  shoeSize\n  height\n  hairColor\n  location\n  id\n  publicName\n  images(limit: 2) {\n    id\n  }\n}\n\nfragment ModelGrid_models on Model {\n  ...ModelBox_model\n  id\n  publicName\n  birthDate\n  male\n  eyeColor\n  shoeSize\n  size\n  minSize\n  maxSize\n  height\n  hairColor\n  location\n  lastContentUpdateDate\n}\n"
  }
};
})();

(node as any).hash = "a899407e5ce28cb2c8b05e28a25431f6";

export default node;
