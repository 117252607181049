import * as React from "react";
import { usePreloadedQuery } from "react-relay";
import { OperationType } from "relay-runtime";

export default function PreloadedQuery<TQuery extends OperationType>({
  query,
  queryReference,
  children,
}: {
  query: Parameters<typeof usePreloadedQuery<TQuery>>[0];
  queryReference: Parameters<typeof usePreloadedQuery<TQuery>>[1];
  children: (
    data: ReturnType<typeof usePreloadedQuery<TQuery>>,
  ) => React.ReactNode;
}) {
  return children(usePreloadedQuery(query, queryReference));
}
