/**
 * @generated SignedSource<<2a2a6a37ffd72616cca01b6d4fa61410>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "NON_BINARY" | "%future added value";
export type CreateModelApplicationInput = {
  address?: string | null | undefined;
  birthDate?: any | null | undefined;
  city?: string | null | undefined;
  country?: string | null | undefined;
  emailAddress?: string | null | undefined;
  firstName?: string | null | undefined;
  gender?: Gender | null | undefined;
  height?: number | null | undefined;
  images?: string | null | undefined;
  imagesLink?: string | null | undefined;
  lastName?: string | null | undefined;
  message?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  size?: number | null | undefined;
  zipCode?: string | null | undefined;
};
export type BecomeAModelMutation$variables = {
  input: CreateModelApplicationInput;
};
export type BecomeAModelMutation$data = {
  readonly createModelApplication: {
    readonly modelApplication: {
      readonly id: string;
    };
  };
};
export type BecomeAModelMutation = {
  response: BecomeAModelMutation$data;
  variables: BecomeAModelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateModelApplicationPayload",
    "kind": "LinkedField",
    "name": "createModelApplication",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ModelApplication",
        "kind": "LinkedField",
        "name": "modelApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BecomeAModelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BecomeAModelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3d9bf38bbf2358c0ac064f1c1893d61",
    "id": null,
    "metadata": {},
    "name": "BecomeAModelMutation",
    "operationKind": "mutation",
    "text": "mutation BecomeAModelMutation(\n  $input: CreateModelApplicationInput!\n) {\n  createModelApplication(input: $input) {\n    modelApplication {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d17e6436be4a9d819b37848619629882";

export default node;
