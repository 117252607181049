import * as React from "react";
import { Helmet } from "react-helmet";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player/file";
import Button, { getKey as getButtonKey } from "../common/Button.tsx";
import { doublePaddedCss } from "../common/css.ts";
import MeasuredDiv from "../common/MeasuredDiv.tsx";
import Footer from "./common/Footer.tsx";
import Image from "../common/Image.tsx";
import ErrorHandler from "../common/ErrorHandler.tsx";
import LoadingIndicator from "../common/LoadingIndicator.tsx";
import Logo from "../common/Logo.tsx";

export default function Home({
  preTitle,
  topButtons,
  instagramId,
  facebookUrl,
  instagramUrl,
  pageName,
}: {
  preTitle: string;
  topButtons: React.ComponentPropsWithoutRef<typeof Button>[];
  instagramId?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  pageName: string;
}): React.ReactElement {
  const relayEnvironment = useRelayEnvironment();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const bannerId = urlSearchParams.get("banner");

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query HomeInstagramQuery(
          $instagramId: String!
          $pageName: String!
          $includeBanner: Boolean!
          $bannerId: ID!
        ) {
          page(namePath: [$pageName]) {
            title
            titleColor
            content
            banners: files(banner: true, ready: true) {
              id
              contentType
              width
              height
              playlistUrl
            }
          }
          instagramAccount(instagramId: $instagramId) {
            media {
              id
              permalink
              timestamp
            }
          }
          banner: file(id: $bannerId) @include(if: $includeBanner) {
            id
            contentType
            width
            height
            playlistUrl
          }
        }
      `}
      variables={{
        instagramId,
        pageName,
        includeBanner: !!bannerId,
        bannerId: bannerId || "",
      }}
      render={({ props, error, retry }) => {
        if (error) return <ErrorHandler error={error} retry={retry} />;

        if (!props)
          return (
            <LoadingIndicator
              css={{
                marginTop: "calc(2 * var(--spacing))",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          );

        const banner =
          props.banner || (props.page?.banners && props.page.banners[0]);
        const titleColor = props.page?.titleColor || "white";

        return (
          <>
            <Helmet title={props.page?.title} />

            <MeasuredDiv
              width
              height
              css={{
                width: "100%",
                height: "70vh",
                position: "relative",
                overflow: "hidden",
                background: "black",
              }}
            >
              {({ width, height }) =>
                !!width &&
                !!height && (
                  <>
                    {(banner?.contentType.startsWith("image") && (
                      <Image
                        src={banner}
                        width={width}
                        height={height}
                        css={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          objectFit: "cover",
                        }}
                      />
                    )) ||
                      (banner?.contentType.startsWith("video") && (
                        <ReactPlayer
                          url={banner.playlistUrl}
                          width={width}
                          height={height}
                          css={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            "> video": {
                              objectFit: "cover",
                            },
                          }}
                          playing
                          muted
                          loop
                          playsinline
                        />
                      ))}
                    <div
                      css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        css={{
                          ...doublePaddedCss,
                          width: "100%",
                          maxWidth: "var(--max-width)",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {preTitle && (
                          <div
                            css={{
                              fontSize: 17,
                              color: titleColor,
                            }}
                          >
                            {preTitle}
                          </div>
                        )}
                        {props.page?.title && (
                          <div
                            css={{
                              fontSize: 50,
                              color: titleColor,
                              lineHeight: 1,
                            }}
                          >
                            {props.page.title}
                          </div>
                        )}
                      </div>
                    </div>

                    {!preTitle && !props.page?.title && (
                      <div
                        css={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Logo
                          color={titleColor}
                          css={{ width: "50%", height: "50%" }}
                        />
                      </div>
                    )}
                  </>
                )
              }
            </MeasuredDiv>

            {topButtons && (
              <div
                css={{
                  marginTop: "calc(2 * var(--spacing))",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  gap: "var(--spacing)",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {topButtons.map((buttonProps) => (
                  <Button
                    key={getButtonKey(buttonProps)}
                    size={1.3}
                    type="naked"
                    {...buttonProps}
                  />
                ))}
              </div>
            )}

            {instagramId && props.instagramAccount && (
              <div
                css={{
                  maxWidth: "calc(3 * (360px + var(--spacing)))",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "calc(2 * var(--spacing))",
                }}
              >
                <div
                  css={{
                    marginLeft: "calc(-1 * var(--spacing))",
                    marginTop: "var(--spacing)",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    ">*": {
                      marginTop: "var(--spacing)",
                      marginLeft: "var(--spacing)",
                    },
                  }}
                >
                  {props.instagramAccount.media
                    .slice()
                    .sort(
                      (media1, media2) =>
                        new Date(media2.timestamp).getTime() -
                        new Date(media1.timestamp).getTime(),
                    )
                    .slice(
                      0,
                      Math.floor(props.instagramAccount.media.length / 3) * 3,
                    )
                    .map((media) => (
                      <a href={media.permalink} target="ig" key={media.id}>
                        <Image
                          src={media}
                          width={360}
                          height={360}
                          fit="crop"
                        />
                      </a>
                    ))}
                </div>
              </div>
            )}

            <Footer
              css={{ marginTop: "calc(2 * var(--spacing))" }}
              facebookUrl={facebookUrl}
              instagramUrl={instagramUrl}
            />
          </>
        );
      }}
    />
  );
}
