/**
 * @generated SignedSource<<76798ca5195d90a54baf202c5defa6b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ModelImagecardsSelectedImagesQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type ModelImagecardsSelectedImagesQuery$data = {
  readonly modelImages: ReadonlyArray<{
    readonly id: string;
    readonly model: {
      readonly id: string;
    };
  }>;
};
export type ModelImagecardsSelectedImagesQuery = {
  response: ModelImagecardsSelectedImagesQuery$data;
  variables: ModelImagecardsSelectedImagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "ModelImage",
    "kind": "LinkedField",
    "name": "modelImages",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "model",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelImagecardsSelectedImagesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModelImagecardsSelectedImagesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "91529f324eb73c195beff8927cd88a0a",
    "id": null,
    "metadata": {},
    "name": "ModelImagecardsSelectedImagesQuery",
    "operationKind": "query",
    "text": "query ModelImagecardsSelectedImagesQuery(\n  $ids: [ID!]!\n) {\n  modelImages(ids: $ids) {\n    id\n    model {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4706edb3f371de82d3b12a70c47ce3ce";

export default node;
