/**
 * @generated SignedSource<<7a91cf21249a9c5abd3bdc69dc285393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ModelBoxImagesQuery$variables = {
  id: string;
};
export type ModelBoxImagesQuery$data = {
  readonly model: {
    readonly images: ReadonlyArray<{
      readonly id: string;
    }> | null | undefined;
  } | null | undefined;
};
export type ModelBoxImagesQuery = {
  response: ModelBoxImagesQuery$data;
  variables: ModelBoxImagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ModelImage",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelBoxImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "model",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModelBoxImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "model",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dd0f6d9fc1e0abb9a0782d90b3c5438",
    "id": null,
    "metadata": {},
    "name": "ModelBoxImagesQuery",
    "operationKind": "query",
    "text": "query ModelBoxImagesQuery(\n  $id: ID!\n) {\n  model(id: $id) {\n    images {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cc8378c470388fbd0955d79c5a99134";

export default node;
