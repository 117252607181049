/**
 * @generated SignedSource<<548cf4db73ab3568f3963dccb450b9a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelListQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type ModelListQuery$data = {
  readonly models: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ModelGrid_models">;
  }>;
};
export type ModelListQuery = {
  response: ModelListQuery$data;
  variables: ModelListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "anyOfIds",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModelGrid_models"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModelListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eyeColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shoeSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hairColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "limit",
                "value": 2
              }
            ],
            "concreteType": "ModelImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": "images(limit:2)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "male",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastContentUpdateDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12c3dabf90417cb664e5f290fa2373a0",
    "id": null,
    "metadata": {},
    "name": "ModelListQuery",
    "operationKind": "query",
    "text": "query ModelListQuery(\n  $ids: [ID!]!\n) {\n  models(anyOfIds: $ids) {\n    ...ModelGrid_models\n    id\n  }\n}\n\nfragment ModelBox_model on Model {\n  minSize\n  maxSize\n  size\n  eyeColor\n  shoeSize\n  height\n  hairColor\n  location\n  id\n  publicName\n  images(limit: 2) {\n    id\n  }\n}\n\nfragment ModelGrid_models on Model {\n  ...ModelBox_model\n  id\n  publicName\n  birthDate\n  male\n  eyeColor\n  shoeSize\n  size\n  minSize\n  maxSize\n  height\n  hairColor\n  location\n  lastContentUpdateDate\n}\n"
  }
};
})();

(node as any).hash = "ec4ce0ce8fc3c12f9649529706164b13";

export default node;
