/**
 * @generated SignedSource<<85a488211cedcb63e148e7d617525283>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelSetcard_model$data = {
  readonly eyeColor: string | null | undefined;
  readonly hairColor: string | null | undefined;
  readonly height: number | null | undefined;
  readonly location: string | null | undefined;
  readonly maxSize: number | null | undefined;
  readonly minSize: number | null | undefined;
  readonly publicName: string | null | undefined;
  readonly setcardImages: ReadonlyArray<{
    readonly url: string;
  }> | null | undefined;
  readonly shoeSize: number | null | undefined;
  readonly size: number | null | undefined;
  readonly statuses: ReadonlyArray<string> | null | undefined;
  readonly " $fragmentType": "ModelSetcard_model";
};
export type ModelSetcard_model$key = {
  readonly " $data"?: ModelSetcard_model$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelSetcard_model">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelSetcard_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shoeSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hairColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statuses",
      "storageKey": null
    },
    {
      "alias": "setcardImages",
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 2
        }
      ],
      "concreteType": "ModelImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "images(limit:2)"
    }
  ],
  "type": "Model",
  "abstractKey": null
};

(node as any).hash = "322e8f2fd5959561f54fc076f4b004d6";

export default node;
