/**
 * @generated SignedSource<<97159a8d3b36632eb4a74baff630315a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageMainQuery$variables = {
  pageNamePath: ReadonlyArray<string>;
};
export type PageMainQuery$data = {
  readonly page: {
    readonly " $fragmentSpreads": FragmentRefs<"Page_page">;
  } | null | undefined;
};
export type PageMainQuery = {
  response: PageMainQuery$data;
  variables: PageMainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageNamePath"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "namePath",
    "variableName": "pageNamePath"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageMainQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Page_page"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageMainQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "pages",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortIndex",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageFile",
            "kind": "LinkedField",
            "name": "files",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c656d047ce194931f2d26d1b0956ce6",
    "id": null,
    "metadata": {},
    "name": "PageMainQuery",
    "operationKind": "query",
    "text": "query PageMainQuery(\n  $pageNamePath: [String!]!\n) {\n  page(namePath: $pageNamePath) {\n    ...Page_page\n    id\n  }\n}\n\nfragment Page_page on Page {\n  id\n  title\n  content\n  pages {\n    title\n    name\n    sortIndex\n    id\n  }\n  files {\n    id\n    name\n    width\n    height\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8acf7c085fbcfd56d396620b6aeb1d3";

export default node;
