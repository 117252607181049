import { useTheme } from "@emotion/react";
import * as React from "react";
import { graphql, useFragment } from "react-relay";
import { useRouteMatch } from "react-router";
import urljoin from "url-join";
import Button from "../../../common/Button.tsx";
import { doublePaddedCss, h2Css, strongCss } from "../../../common/css.ts";
import HeartIcon from "../../../common/icons/HeartIcon.tsx";
import InstagramIcon from "../../../common/icons/InstagramIcon.tsx";
import Image from "../../../common/Image.tsx";
import MeasuredDiv from "../../../common/MeasuredDiv.tsx";
import nl2br from "../../../common/nl2br.tsx";
import getModelDetails from "../../Models/common/getModelDetails.tsx";
import { useModelList } from "../ModelListContext.tsx";
import Footer from "../Footer.tsx";
import getFacebookUrlFromStatuses from "../getFacebookUrlFromStatuses.ts";
import getInstagramUrlFromStatuses from "../getInstagramUrlFromStatuses.ts";
import { ModelHome_model$key } from "./__generated__/ModelHome_model.graphql.ts";
import config from "../../../config";
import DownArrowIcon from "../../../common/icons/DownArrowIcon.tsx";
import VideoPlayer from "../../../common/VideoPlayer.tsx";

export default function ModelHome({
  model: modelProp,
}: {
  model: ModelHome_model$key;
}): React.ReactElement {
  const model = useFragment(
    graphql`
      fragment ModelHome_model on Model {
        ...getModelDetails_model @relay(mask: false)
        id
        publicName
        info
        instagramName
        images {
          id
          contentType
          width
          height
          fullWidth
        }
        polaroids: images(category: "polaroids", limit: 1) {
          id
          url
        }
        statuses
        videos(ready: true) {
          id
          name
          playlistUrl
          width
          height
        }
      }
    `,
    modelProp,
  );

  const {
    params: { modelId },
  } = useRouteMatch();
  const theme = useTheme();
  const modelList = useModelList();
  const [showVideos, setShowVideos] = React.useState(false);
  const [selectedVideoId, setSelectedVideoId] = React.useState<string>();
  const inModelList = modelList?.modelIds?.includes(modelId);

  const details = getModelDetails(model);

  return (
    <div
      css={{
        ...doublePaddedCss,
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
      }}
    >
      <div
        css={{
          width: "100%",
          maxWidth: "var(--max-width)",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div css={{ marginBottom: "calc(2 * var(--spacing))" }}>
          <div
            css={{
              ...h2Css,
              textAlign: "center",
            }}
          >
            {model.publicName}
          </div>

          {model.info && (
            <div css={{ marginBottom: "calc(2 * var(--spacing))" }}>
              {nl2br(model.info)}
            </div>
          )}

          {details.length > 0 && (
            <div
              css={{
                justifyTracks: "center",
                display: ["flex", "grid"],
                flexWrap: "wrap",
                rowGap: "var(--spacing)",
                columnGap: "calc(2 * var(--spacing))",
                gridTemplateColumns:
                  "repeat(auto-fit, minmax(100px, max-content))",
                justifyContent: "center",
                marginBottom: "calc(2 * var(--spacing))",
                ">*": {
                  lineHeight: 1,
                  whiteSpace: "nowrap",
                },
              }}
            >
              {details.map((detail) => (
                <div key={detail.name}>
                  <span css={strongCss}>{detail.name}:</span> {detail.value}
                </div>
              ))}
            </div>
          )}

          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "calc(-1 * var(--spacing))",
              ">*": {
                marginTop: "var(--spacing)",
                ":not(:last-child)": {
                  marginRight: "var(--spacing)",
                },
              },
            }}
          >
            <Button
              type="secondary"
              icon={
                <HeartIcon
                  height="var(--icon-size)"
                  color={
                    inModelList ? "var(--text-color)" : "var(--note-color)"
                  }
                  solid={inModelList}
                />
              }
              onClick={() =>
                inModelList
                  ? modelList.removeModel(modelId)
                  : modelList.addModel(modelId)
              }
            />
            {model.instagramName && (
              <Button
                type="secondary"
                iconComponent={InstagramIcon}
                href={`https://instagram.com/${model.instagramName}`}
                target="_blank"
              />
            )}
            <Button
              type="secondary"
              href={urljoin(
                config.restApi.baseUrl,
                "models",
                model.id,
                "setcard",
              )}
              target="_blank"
            >
              Setcard
            </Button>
            <Button
              type="secondary"
              href={urljoin(
                config.restApi.baseUrl,
                "models",
                model.id,
                "imagecards",
              )}
              target="_blank"
            >
              PDF all images
            </Button>

            {model.polaroids.length > 0 && (
              <Button
                href={model.polaroids[0].url}
                target="_blank"
                type="secondary"
              >
                Polaroid sheet
              </Button>
            )}

            {model.videos.length > 0 && (
              <Button
                type="secondary"
                iconComponent={DownArrowIcon}
                iconPosition="right"
                onClick={() => {
                  if (showVideos) setShowVideos(false);
                  else {
                    setSelectedVideoId(model.videos[0].id);
                    setShowVideos(true);
                  }
                }}
              >
                {model.videos.length > 1 ? "Videos" : "Video"}
              </Button>
            )}
          </div>
        </div>

        <MeasuredDiv width css={{ marginBottom: "calc(2 * var(--spacing))" }}>
          {({ width }) => {
            if (!width) return null;

            const imagesPerRow = width > theme.maxWidth / 2 ? 2 : 1;
            const imageWidth =
              (width - theme.spacing * 2 * (imagesPerRow - 1)) / imagesPerRow;
            let videosOnRow = 0;

            const selectedVideo = model.videos.find(
              (v) => v.id === selectedVideoId,
            );

            return (
              <>
                {showVideos && (
                  <>
                    {model.videos.length > 1 && (
                      <div
                        css={{
                          display: "flex",
                          gap: "var(--spacing)",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {model.videos.map((video) => (
                          <Button
                            type="tab"
                            key={video.id}
                            active={video.id === selectedVideoId}
                            onClick={() => setSelectedVideoId(video.id)}
                          >
                            {video.name}
                          </Button>
                        ))}
                      </div>
                    )}
                    <MeasuredDiv
                      height
                      css={{
                        display: "flex",
                        height: "60vh",
                        marginTop: "var(--spacing)",
                        marginBottom: "calc(2 * var(--spacing))",
                        background: "black",
                      }}
                    >
                      {({ height }) => {
                        return (
                          <VideoPlayer
                            url={selectedVideo.playlistUrl}
                            width={width}
                            height={height}
                            autoPlay={model.videos.length === 1}
                          />
                        );
                      }}
                    </MeasuredDiv>
                  </>
                )}
                <div
                  css={{
                    marginTop: "calc(-2 * var(--spacing))",
                    marginLeft: "calc(-2 * var(--spacing))",
                    ">*": {
                      marginTop: "calc(2 * var(--spacing))",
                      marginLeft: "calc(2 * var(--spacing))",
                    },
                  }}
                >
                  {model.images.map((image, index) => {
                    const fullRow =
                      image.fullWidth ||
                      Number(image.width) > Number(image.height) ||
                      (videosOnRow === 0 &&
                        ((model.images.length > index + 1 &&
                          Number(model.images[index + 1].width) >
                            Number(model.images[index + 1].height)) ||
                          index === model.images.length - 1));
                    videosOnRow += 1;
                    if (videosOnRow === imagesPerRow || fullRow)
                      videosOnRow = 0;

                    return (
                      <div
                        key={image.id}
                        css={{ display: "inline-block", position: "relative" }}
                      >
                        <Image
                          src={image}
                          width={
                            fullRow
                              ? imagesPerRow * imageWidth +
                                theme.spacing * 2 * (imagesPerRow - 1)
                              : imageWidth
                          }
                        />
                        <div
                          css={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            opacity: 0,
                            "&:hover": { opacity: 1 },
                            transition: "opacity .1s ease-in-out",
                          }}
                        >
                          <Button
                            type="secondary"
                            css={{
                              position: "absolute",
                              top: "var(--spacing)",
                              right: "var(--spacing)",
                              lineHeight: 1,
                            }}
                            href={urljoin(
                              config.restApi.baseUrl,
                              "models",
                              model.id,
                              `imagecards?imageId=${image.id}`,
                            )}
                            target="_blank"
                          >
                            PDF
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }}
        </MeasuredDiv>

        <Footer
          facebookUrl={getFacebookUrlFromStatuses(model.statuses)}
          instagramUrl={getInstagramUrlFromStatuses(model.statuses)}
        />
      </div>
    </div>
  );
}
